import Api from '../base/index.js'

export default{
    async user(userId){
        return Api.get(`user/${userId}`)
    },

    async updateProfile(data, userId){
        return Api.put(`user/${userId}`, data)
    }
}