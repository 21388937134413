import Appeal from "../../../apis/appeal/appeal.js";
export default {
  namespaced: true,

  state() {
    return {
      resources: {
        data: [],
        current_page: 0,
        last_page: 1,
        total: 0,
      },

      //   allResources: [],
      allInvoiceStat: null,
      showResource: null,

      keys: {
        resources: {
          localStorageName: "appeals",
        },
      },

      filters: {
        session_id: null,
        appeal_type_id: null,
      },

      segment: "all",
    };
  },

  getters: {
    resourcesKey(state) {
      return state.keys.resources;
    },

    resources(state) {
      return state.resources;
    },

    filters(state) {
      return state.filters;
    },

    segment(state) {
      return state.segment;
    },

    // allResources(state){
    //   return state.allResources;
    // },

    showResource(state) {
      return state.showResource;
    },

    nextResourcesPage(_, getters) {
      const resources = getters.resources;
      const current_page = resources.current_page;
      const last_page = resources.last_page;
      return last_page > current_page ? current_page + 1 : false;
    },
  },

  mutations: {
    resources(state, payLoad) {
      if (payLoad.refresh) {
        state.resources.data = payLoad.response.data;
      } else {
        state.resources.data.push(...payLoad.response.data);
      }
      state.resources.current_page = payLoad.response.current_page;
      state.resources.last_page = payLoad.response.last_page;
      state.resources.total = payLoad.response.total;
      localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    },

    filters(state, payLoad) {
      state.filters = payLoad;
    },

    segment(state, payLoad) {
      state.segment = payLoad;
    },
    // allResources(state, payLoad){
    //   state.allResources = payLoad;
    // }

    showResource(state, payLoad) {
      state.showResource = payLoad;
    },
  },

  actions: {
    async resources(context, payLoad) {
      const refresh = payLoad.refresh ? true : false;
      const key = context.getters.resourcesKey;
      const filters = context.getters.filters;
      const segment = context.getters.segment;
      const inLocalStorage = refresh
        ? null
        : await JSON.parse(localStorage.getItem(key.localStorageName));

      if (inLocalStorage) {
        context.state.resources = inLocalStorage;
      }

      const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;

      if (nextResourcesPage) {
        //  const response = await ImportedApi.index(page)
        const response = await Appeal.index(
          nextResourcesPage,
          filters,
          segment
        );

        for(let key in response.data.data){
          response.data.data[key].user.student = response.data.data[key].user.student_with_trashed
          delete response.data.data[key].user.student_with_trashed
        }

        const dispatch = {
          response: response.data,
          refresh: refresh,
          key: key.localStorageName,
        };
        context.commit("resources", dispatch);
      }
    },

    async filters(context, payLoad) {
      await context.commit("filters", payLoad);
      context.dispatch("resources", { refresh: true });
    },

    async segment(context, payLoad) {
      await context.commit("segment", payLoad);
      context.dispatch("resources", { refresh: true });
    },

    async createResource(context, payLoad) {
      // const response = await ImportedApi.create(resource_id)
      await Appeal.create(payLoad);
      context.dispatch("resources", { refresh: true });
    },

    async updateResource(context, payLoad) {
      // const response = await ImportedApi.update(resource_id, data)
      await Appeal.update(payLoad.id, payLoad);
      context.dispatch("resources", { refresh: true });
    },

    async deleteResource(context, payLoad) {
      // const response = await ImportedApi.delete(resource_id)
      await Appeal.delete(payLoad);
      context.dispatch("resources", { refresh: true });
    },

    // async allResources(context){
    //   // All resources without pagination
    //   const allResources = await Invoice.all();
    //   context.commit("allResources", allResources.data)
    // },
    // async showResource(context, payLoad) {
    //   const showResource = await Invoice.show(payLoad.resourceId);
    //   context.commit("showResource", showResource.data);
    // },
  },
};
