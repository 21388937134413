import ExamQuestion from "../../../apis/exam/examQuestion.js";
export default {
  namespaced: true,

  state() {
    return {
      specificResource: null,
    };
  },

  getters: {
    specificResource(state){
      return state.specificResource;
    },
  },

  mutations: {
    specificResource(state, payLoad){
      state.specificResource = payLoad;
    }
  },

  actions: {
    async specificResource(context, payLoad) {
      // Specific resources without pagination
      const specificResource = await ExamQuestion.show(payLoad);
      context.commit("specificResource", specificResource.data)
    }
  },
};
