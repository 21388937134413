import Api from '../base/index.js'

export default{
    async gender(){
        return Api.get(`gender`)
    },

    async bloodGroup(){
        return Api.get(`blood-group`)
    },
    async appealType(){
        return Api.get(`appeal-type`)
    },
    async examAnswerState(){
        return Api.get('exam-answer-state')
    }
}