import Api from '../base/index.js'

export default{
    async all(data){
        return Api.get(`student-exam-result`, {
            params : {
                exam_id: data.exam_id,
            }
        })
    },
}