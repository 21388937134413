<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start" class="ion-hide-lg-down">
          <ion-back-button
            v-if="currentRouteName != backLink"
            :default-href="{ name: backLink }"
          ></ion-back-button>
        </ion-buttons>

        <ion-buttons slot="end" class="ion-hide-lg-down" v-if="reloadable">
          <ion-button @click="reload()" fill="outline">Reload</ion-button>
        </ion-buttons>
        <ion-title class="ion-text-capitalize">
          {{ pageTitle }}
        </ion-title>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
      <slot name="search-bar"></slot>
    </ion-header>

    <ion-content :fullscreen="true">
      <slot>
        <div style="margin-top: 40vh; text-align: center">
          <h3>Awesone content coming soon..</h3>
        </div>
      </slot>
    </ion-content>

    <ion-footer v-if="footer">
      <slot name="footer"></slot>
    </ion-footer>
  </ion-page>
</template>

<script>
// import { getPlatforms } from "@ionic/vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonBackButton,
  IonTitle,
  IonContent,
  IonFooter,
  alertController,
} from "@ionic/vue";
export default {
  props: {
    title: {
      require: false,
      default: null,
    },
    backLink: {
      require: false,
      default: "dashboard",
    },
    reloadable: {
      require: false,
      default: null,
    },
    footer: {
      require: false,
      default: false,
    },
  },

  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonBackButton,
    IonTitle,
    IonContent,
    IonFooter,
  },

  data() {
    return {
      // platform: null,
      refreshing: false,
      registration: null,
      updateExists: false,
      offline: false,
    };
  },

  computed: {
    pageTitle() {
      return this.title;
    },

    currentRouteName() {
      return this.$route.name;
    },

    auth() {
      return this.$store.getters["auth/isAuthenticated"] ? "auth" : "guest";
    },
  },

  watch: {
    updateExists() {
      if (this.updateExists) {
        this.showUpdateAlert();
      }
    },
    offline() {
      if (this.offline) {
        this.showOfflineAlert();
      }
    },
  },

  created() {
    // Listen for swUpdated event and display refresh alert as required.
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // Listen for offline event and display offline alert as required.
    document.addEventListener("offline", this.showOfflineUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },

  methods: {
    reload() {
      this.$store.dispatch("setReload", this.$route.name);
    },
    showRefreshUI(e) {
      // Display a alert modal inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.updateExists = true;
    },
    showOfflineUI(e) {
      // Display a alert modal inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.offline = true;
    },
    refreshApp() {
      // Handle a user tap on the update app button.
      this.updateExists = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    async showUpdateAlert() {
      const header = `Update Available`;
      const message = "A new version of this app is available. Click 'Update' to update.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Update",
          handler: () => {
            this.refreshApp();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async showOfflineAlert(){
      const header = `No Internet Connection`;
      const message = "You are currently offline. Please connect to the internet and try again.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Retry",
          handler: () => {
            location.reload();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },
  },

  mounted() {
    // this.platforms = getPlatforms();
  },
};
</script>

<style scoped>
</style>