import Event from "../../../apis/calendar/event.js";
import moment from "moment";
export default {
  namespaced: true,

  state() {
    return {
      resources: {
        data: [],
        current_page: 0,
        last_page: 1,
        total: 0,
      },

      // allResources: [],

      // types: [],

      keys: {
        resources: {
          localStorageName: "events",
        },
      },
    };
  },

  getters: {
    resourcesKey(state) {
      return state.keys.resources;
    },

    resources(state) {
      return state.resources;
    },

    // allResources(state){
    //   return state.allResources;
    // },

    // types(state) {
    //   return state.types;
    // },

    nextResourcesPage(_, getters) {
      const resources = getters.resources;
      const current_page = resources.current_page;
      const last_page = resources.last_page;
      return last_page > current_page ? current_page + 1 : false;
    },
  },

  mutations: {
    resources(state, payLoad) {
      if (payLoad.refresh) {
        state.resources.data = payLoad.response.data;
      } else {
        state.resources.data.push(...payLoad.response.data);
      }
      state.resources.current_page = payLoad.response.current_page;
      state.resources.last_page = payLoad.response.last_page;
      state.resources.total = payLoad.response.total;
      localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    },

    // allResources(state, payLoad){
    //   state.allResources = payLoad;
    // },

    // types(state, payLoad) {
    //   state.types = payLoad;
    // },
  },

  actions: {
    async resources(context, payLoad) {
      const refresh = payLoad.refresh ? true : false;
      const key = context.getters.resourcesKey;
      const inLocalStorage = refresh
        ? null
        : await JSON.parse(localStorage.getItem(key.localStorageName));

      if (inLocalStorage) {
        context.state.resources = inLocalStorage;
      }

      const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;

      if (nextResourcesPage) {
        const startOfMonth = payLoad.startOfMonth
          ? payLoad.startOfMonth
          : moment()
              .startOf("month")
              .format("YYYY-MM-DD");

        //  const response = await ImportedApi.index(page)

        const response = await Event.index(nextResourcesPage, startOfMonth);

        const dispatch = {
          response: response.data,
          refresh: refresh,
          key: key.localStorageName,
        };

        context.commit("resources", dispatch);
      }
    },

    // async createResource(context, payLoad) {
    //   // const response = await ImportedApi.create(resource_id)
    //   await Event.create(payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    // async updateResource(context, payLoad) {
    //   // const response = await ImportedApi.update(resource_id, data)
    //   const data = payLoad
    //   await Event.update(payLoad.id, data);
    //   context.dispatch("resources", { refresh: true });
    // },

    // async deleteResource(context, payLoad) {
    //   // const response = await ImportedApi.delete(resource_id)
    //   await Event.delete(payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    // async allResources(context){
    //   // All resources without pagination
    //   const allResources = await Event.all();
    //   context.commit("allResources", allResources.data)
    // },

    // async types(context) {
    //   // All resources without pagination
    //   const types = await Event.eventTypes();
    //   context.commit("types", types.data);
    // },
  },
};
