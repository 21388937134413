import PwaAuth from './pwa/Auth.js'
import ApiAuth from './api/Auth.js'


// import { getPlatforms } from '@ionic/vue';
// const platforms = getPlatforms()
// const isApp = true || platforms.includes("hybrid")
const isApp = true

const Auth = isApp ? ApiAuth : PwaAuth

export default Auth






