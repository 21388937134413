import Api from "./Api.js";

export default {
  async register(form) {
    return Api.post("register", form);
  },

  async login(form) {
    return Api.post("student-login", form);
  },

  async logout() {
    return Api.post("logout");
  },
};
