import Api from '../base/index.js'

export default{
    async index(page=1, date){
        return Api.get(`student-attendance?page=${page}&attendance_date=${date}`)
    },

    async attendanceForSession(user_id){
        return Api.get(`student-attendance-for-session/${user_id}`)
    },

    // async all(){
    //     return Api.get(`attendance-all`)
    // },

    // async show(id){
    //     return Api.get(`attendance/${id}`)
    // },

    // async create(data){
    //     return Api.post(`attendance`, data)
    // },

    // async update(id, data){
    //     return Api.put(`attendance/${id}`, data)
    // },

    // async delete(id){
    //     return Api.delete(`attendance/${id}`)
    // }
}