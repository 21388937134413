import Api from '../base/index.js'

export default{
    // async index(page=1){
    //     return Api.get(`subject-group?page=${page}`)
    // },

    async all(){
        return Api.get(`subject-group-all`)
    },

    // async create(data){
    //     return Api.post(`subject-group`, data)
    // },

    // async update(id, data){
    //     return Api.put(`subject-group/${id}`, data)
    // },
    
    // async delete(id){
    //     return Api.delete(`subject-group/${id}`)
    // }
}