import Attribute from "../../../apis/atribute/attribute.js";
export default {
  namespaced: true,

  state() {
    return {
      genders: [],
      bloodGroups: [],
      appealTypes: [],
      examAnswerState: [],
    };
  },

  getters: {
    genders(state){
      return state.genders;
    },
    bloodGroups(state){
      return state.bloodGroups;
    },
    appealTypes(state){
      return state.appealTypes;
    },
    examAnswerState(state){
      return state.examAnswerState;
    }
  },

  mutations: {
    genders(state, payLoad){
      state.genders = payLoad;
    },
    bloodGroups(state, payLoad){
      state.bloodGroups = payLoad;
    },
    appealTypes(state, payLoad){
      state.appealTypes = payLoad;
    },
    examAnswerState(state, payLoad){
      state.examAnswerState = payLoad;
    }
  },

  actions: {
    async genders(context){
      // All resources without pagination
      const genders = await Attribute.gender();
      context.commit("genders", genders.data)
    },
    async bloodGroups(context){
      // All resources without pagination
      const bloodGroups = await Attribute.bloodGroup();
      context.commit("bloodGroups", bloodGroups.data)
    },
    async appealTypes(context){
      // All resources without pagination
      const appealTypes = await Attribute.appealType();
      context.commit("appealTypes", appealTypes.data)
    },
    async examAnswerState(context){
      // All resources without pagination
      const examAnswerState = await Attribute.examAnswerState();
      context.commit("examAnswerState", examAnswerState.data)
    }
  },
};
