import Api from "../base/index.js";

export default {

  async show(id) {
    return Api.get(`razorpay-fee-invoice/${id}`);
  },
  
  async verifyPayment(id, data) {
    return Api.post(`razorpay-verify-payment/${id}`, data);
  },

};
