import tinyMce from "../../../apis/tinyMce/tinyMce.js";
export default {
  namespaced: true,

  state() {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async uploadImage(_, payLoad) {
        console.log(payLoad);
      let response = await tinyMce.uploadImage(payLoad);
      return response;
    },
  },
};
