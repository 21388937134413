import Api from '../base/index.js'

export default{
    // async index(page=1){
    //     return Api.get(`fee?page=${page}`)
    // },

    async all(){
        return Api.get(`fee-all`)
    },

    // async show(id){
    //     return Api.get(`fee/${id}`)
    // },

    // async create(data){
    //     return Api.post(`fee`, data)
    // },

    // async update(id, data){
    //     return Api.put(`fee/${id}`, data)
    // },

    // async delete(id){
    //     return Api.delete(`fee/${id}`)
    // }
}