/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log('Service worker has been registered.');

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 30); // check every 30 minutes
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log('New content is available; please refresh.');

      // Add a custom event and dispatch it.
      // Used to display of a 'refresh' banner following a service worker update.
      // Set the event payload to the service worker registration object.

      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline(registration) {
      console.log(
        "No internet connection found. App is running in offline mode."
      );

      document.dispatchEvent(
        new CustomEvent('offline', { detail: registration })
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}