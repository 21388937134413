import Api from '../base/index.js'

export default{
    // async index(page=1, id, filters){
    //     return Api.get(`admit-card/${id}`, {
    //         params : {
    //             page: page,
    //             standard_id: filters.standard_id,
    //             section_id: filters.section_id,
    //             exam_id: filters.exam_id,
    //             user_id: filters.user_id,
    //         }
    //     } )
    // },

    async show(id){
        return Api.get(`admit-card/${id}`)
    },

    // async all(){
    //     return Api.get(`admit-card-all-student`)
    // },

    // async create(data){
    //     return Api.post(`admit-card`, data)
    // },

    // async update(id, data){
    //     return Api.put(`admit-card/${id}`, data)
    // },
    
    // async delete(id){
    //     return Api.delete(`admit-card/${id}`)
    // }
}