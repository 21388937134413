import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";
import sessionModule from "./modules/Setup/session.js";
import standardModule from "./modules/Setup/standard.js";
import feeModule from "./modules/due/fee.js";
import attributeModule from "./modules/attribute/attribute.js";

import subjectModule from "./modules/academic/subject.js";
import subjectGroupModule from "./modules/academic/subjectGroup.js";
import chapterModule from "./modules/academic/chapter.js";
import chapterProgressionModule from "./modules/academic/chapterProgression.js";

import invoiceModule from "./modules/due/invoice.js";
import razorpayModule from "./modules/razorpay/razorpay.js";

import attendanceModule from "./modules/attendance/attendance.js";

import homeworkModule from "./modules/homework/homework.js";

import examModule from "./modules/exam/exam.js";
import admitCardModule from "./modules/exam/admitCard.js";
// import examScheduleModule from "./modules/exam/examSchedule.js";
// import examSectionStandardModule from "./modules/exam/examSectionStandard.js";
import examSubjectModule from "./modules/exam/examSubject.js";
import examQuestionModule from "./modules/exam/examQuestion.js";
import examAnswerModule from "./modules/exam/examAnswer.js";
import examUserModule from "./modules/exam/examUser.js";
import studentExamResult from "./modules/exam/studentExamResult.js";

import appealModule from "./modules/appeal/appeal.js";

import eventModule from "./modules/calendar/event.js"

import notificationModule from "./modules/notification/notification.js";

import tinyMce from "./modules/tinyMce/tinyMce.js";

const store = createStore({
  modules: {
    auth: authModule,

    session: sessionModule,
    standard: standardModule,
    fee: feeModule,

    attribute: attributeModule,

    subject:subjectModule,
    subjectGroup: subjectGroupModule,
    chapter:chapterModule,
    chapterProgression: chapterProgressionModule,

    invoice: invoiceModule,
    razorpay: razorpayModule,

    attendance: attendanceModule,

    homework: homeworkModule,

    exam: examModule,
    examSubject: examSubjectModule,
    examAnswer: examAnswerModule,
    examQuestion: examQuestionModule,
    examUser: examUserModule,
    admitCard: admitCardModule,
    studentExamResult,

    appeal: appealModule,

    event:eventModule,

    tinyMce,

    notification: notificationModule
  },

  state() {
    return {
      reload: null,
    };
  },

  getters: {
    reload(state) {
      return state.reload;
    },
  },

  mutations: {
    clearReload(state) {
      state.reload = null;
    },

    setReload(state, payLoad) {
      state.reload = payLoad;
    },
  },

  actions: {
    clearReload(context) {
      context.commit("clearReload");
    },

    setReload(context, payLoad) {
      context.commit("setReload", payLoad);
    },
  },
});

export default store;
