import Attendance from "../../../apis/attendance/attendance.js";

export default {
  namespaced: true,

  state() {
    return {
      resources: {
        data: [],
        current_page: 0,
        last_page: 1,
        total: 0,
      },

      attendanceForSession: null,

      keys: {
        resources: {
          localStorageName: "attendanceStudent",
        },
      },
    };
  },

  getters: {
    resourcesKey(state) {
      return state.keys.resources;
    },
    resources(state) {
      return state.resources;
    },
    attendanceForSession(state) {
      return state.attendanceForSession;
    },
    nextResourcesPage(_, getters) {
      const resources = getters.resources;
      const current_page = resources.current_page;
      const last_page = resources.last_page;
      return last_page > current_page ? current_page + 1 : false;
    },
  },

  mutations: {
    resources(state, payLoad) {
      if (payLoad.refresh) {
        state.resources.data = payLoad.response.data;
      } else {
        state.resources.data.push(...payLoad.response.data);
      }
      state.resources.current_page = payLoad.response.current_page;
      state.resources.last_page = payLoad.response.last_page;
      state.resources.total = payLoad.response.total;
      localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    },

    attendanceForSession(state, payLoad) {
      state.attendanceForSession = payLoad;
    },
  },

  actions: {
    async resources(context, payLoad) {
      const refresh = payLoad.refresh ? true : false;
      const key = context.getters.resourcesKey;
      const inLocalStorage = refresh
        ? null
        : await JSON.parse(localStorage.getItem(key.localStorageName));

      if (inLocalStorage) {
        context.state.resources = inLocalStorage;
      }

      const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;

      const date = payLoad.attendance_date;

      if (nextResourcesPage) {
        //  const response = await ImportedApi.index(page)
        const response = await Attendance.index(nextResourcesPage, date);

        const dispatch = {
          response: response.data,
          refresh: refresh,
          key: key.localStorageName,
        };
        context.commit("resources", dispatch);
      }
    },

    // async createResource(_, payLoad) {
    //   // const response = await ImportedApi.create(resource_id)
    //   await Attendance.create(payLoad);
    // //   context.dispatch("resources", { refresh: true });
    // },

    // async updateResource(_, payLoad) {
    //   // const response = await ImportedApi.update(resource_id, data)
    //   const id = payLoad.id
    //   const data = payLoad.data
    //   await Attendance.update(id, data);
    //   //   context.dispatch("resources", { refresh: true });
    // },

    // async deleteResource(context, payLoad) {
    //   // const response = await ImportedApi.delete(resource_id)
    //   await Class.delete(payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    async attendanceForSession(context, user_id) {
      // All resources without pagination
      const attendanceForSession = await Attendance.attendanceForSession(user_id);
      context.commit("attendanceForSession", attendanceForSession.data);
    },
  },
};
