import Api from '../base/index.js'

export default{
    // async studentBySectionStandard(){
    //     return Api.get(`student-by-section-standard`)
    // },

    // async sendNotification(data){
    //     return Api.post(`notification`, data)
    // },

    async index(page = 1){
        return Api.get(`notification?page=${page}`)
    },

    // async delete(id){
    //     return Api.delete(`notification/${id}`)
    // }
}