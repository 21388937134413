import Api from '../base/index.js'

export default{
    async index(page=1, id){
        return Api.get(`exam-question/${id}`, {
            params : {
                page: page,
            }
        } )
    },

    async show(id){
        return Api.get(`exam-question-show/${id}`)
    },

    async all(id){
        return Api.get(`exam-question-all/${id}`)
    },

    async create(data){
        return Api.post(`exam-question`, data)
    },

    async update(id, data){
        return Api.put(`exam-question/${id}`, data)
    },
    
    async delete(id){
        return Api.delete(`exam-question/${id}`)
    }
}