import StudentExamResult from "../../../apis/exam/studentExamResult.js";
export default {
  namespaced: true,

  state() {
    return {
      allResources: [],
    };
  },

  getters: {
    allResources(state) {
      return state.allResources;
    },
  },

  mutations: {
    allResources(state, payLoad) {
      state.allResources = payLoad;
    },
  },

  actions: {
    async allResources(context, payLoad) {
      // All resources without pagination
      const data = {exam_id: payLoad};
      const allResources = await StudentExamResult.all(data);
      context.commit("allResources", allResources.data);
    },
  },
};
