import Api from "../base/index.js";

export default {
  async index(page=1, id){
      return Api.get(`chapter-progression`, {
          params : {
              page: page,
              chapter_id: id,
          }
      } )
  },

  // async all(){
  //     return Api.get(`chapter-all`)
  // },

//   async show(id) {
//     return Api.get(`chapter/${id}`);
//   },

  // async create(data){
  //     return Api.post(`chapter-progression`, data)
  // },

  // async update(id, data) {
  //   return Api.put(`chapter-progression/${id}`, data);
  // },

  // async delete(id){
  //     return Api.delete(`chapter/${id}`)
  // }
};
