import { createRouter, createWebHistory } from "@ionic/vue-router";
import store from "../store/index.js";

const routes = [
  {
    path: "",
    redirect: "/dashboard",
  },

  {
    path: "/login",
    component: () => import("../views/Pages/Auth/Login.vue"),
    meta: { requiresUnauth: true },
    name: "login",
  },

  {
    path: "/dashboard",
    component: () => import("../views/Pages/Main/Dashboard.vue"),
    meta: { requiresAuth: true },
    name: "dashboard",
  },

  {
    path: "/profile",
    component: () => import("../views/Pages/Auth/Profile.vue"),
    meta: { requiresAuth: true },
    name: "profile",
  },

  {
    path: "/profile-update",
    component: () => import("../views/Pages/Auth/ProfileUpdate.vue"),
    meta: { requiresAuth: true },
    name: "profile.update",
  },

  {
    path: "/due",
    component: () => import("../views/Pages/Due/Due.vue"),
    meta: { requiresAuth: true },
    name: "due",
  },

  {
    path: "/invoice",
    component: () => import("../views/Pages/Due/Invoice.vue"),
    meta: { requiresAuth: true },
    name: "invoice",
    props: true,
  },

  {
    path: "/invoice/:invoice",
    component: () => import("../views/Pages/Due/InvoiceShow.vue"),
    meta: { requiresAuth: true },
    name: "invoice.show",
    props: true,
  },

  {
    path: "/payment_successfull/:invoice",
    component: () => import("../views/Pages/Due/PaymentSuccessfull.vue"),
    meta: { requiresAuth: true },
    name: "payment.successfull",
    props: true,
  },

  {
    path: "/attendance",
    component: () => import("../views/Pages/Attendance/Attendance.vue"),
    meta: { requiresAuth: true },
    name: "attendance",
  },

  {
    path: "/homework",
    component: () => import("../views/Pages/Homework/Homework.vue"),
    meta: { requiresAuth: true },
    name: "homework",
  },

  {
    path: "/exam/list",
    component: () => import("../views/Pages/Exam/ListExam.vue"),
    meta: { requiresAuth: true },
    name: "exam.list",
  },

  {
    path: "/exam/subject/:exam/:title",
    component: () => import("../views/Pages/Exam/ExamSubject.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.subject",
  },

  {
    path: "/exam/admit-card/:exam/:title",
    component: () => import("../views/Pages/Exam/AdmitCard.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.admit-card",
  },

  {
    path: "/exam/result/:exam/:title",
    component: () => import("../views/Pages/Exam/ExamResult.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.result",
  },

  {
    path: "/exam/online/:exam_subject_id/:exam_name/:subject_name",
    component: () => import("../views/Pages/Exam/OnlineExam.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.online",
  },

  {
    path: "/appeal",
    component: () => import("../views/Pages/Appeal/Appeal.vue"),
    meta: { requiresAuth: true },
    name: "appeal",
  },

  {
    path: "/academic",
    component: () => import("../views/Pages/Academic/Academic.vue"),
    meta: { requiresAuth: true },
    name: "academic",
  },

  {
    path: "/academic/subject",
    component: () => import("../views/Pages/Academic/Subject.vue"),
    meta: { requiresAuth: true },
    name: "subject",
  },

  {
    path: "/academic/subject-chapter/:subject/:title",
    component: () => import("../views/Pages/Academic/SubjectChapter.vue"),
    meta: { requiresAuth: true },
    name: "subject.chapter",
    props: true,
  },
  

  {
    path: "/academic/subject-chapter/chapter-progression/:chapter/:description",
    component: () => import("../views/Pages/Academic/ChapterProgression.vue"),
    meta: { requiresAuth: true },
    name: "chapter.progression",
    props: true,
  },

  {
    path: "/calendar",
    component: () => import("../views/Pages/Calendar/Calendar.vue"),
    meta: { requiresAuth: true },
    name: "calendar",
  },

  {
    path: "/notification-show",
    component: () =>
      import("../views/Pages/Communication/Notification/NotificationShow.vue"),
    meta: { requiresAuth: true },
    name: "notification.show",
  },

  {
    path: "/logedout",
    component: () => import("../views/Pages/LogedOut.vue"),
    name: "logedout",
  },

  {
    path: "/:notFound(.*)",
    component: () => import("../views/Pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const auth = store.dispatch("auth/tryLogin");

router.beforeEach(async function (to, _, next) {
  try {
    if (to.meta.requiresAuth && !store.getters["auth/isAuthenticated"]) {
      await auth;
      next("/login");
    } else if (
      to.meta.requiresUnauth &&
      store.getters["auth/isAuthenticated"]
    ) {
      next("/dashboard");
    } else {
      next();
    }
  } catch (error) {
    console.log(error);
  }
});

export default router;
