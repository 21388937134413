import ExamSubject from "../../../apis/exam/examSubject.js";
export default {
  namespaced: true,

  state() {
    return {
      resources: {
        data: [],
        current_page: 0,
        last_page: 1,
        total: 0,
      },

      allResources: [],
      active: [],

      specificResource: null,

      segment: "active",

      keys: {
        resources: {
          localStorageName: "ExamSubject",
        },
      },

      filters: {
        standard_id: null,
        subject_group_id: null,
        exam_schedule_id: null,
      },
    };
  },

  getters: {
    resourcesKey(state) {
      return state.keys.resources;
    },

    resources(state) {
      return state.resources;
    },

    allResources(state) {
      return state.allResources;
    },

    active(state) {
      return state.active;
    },

    filters(state) {
      return state.filters;
    },

    segment(state) {
      return state.segment;
    },

    specificResource(state) {
      return state.specificResource;
    },

    nextResourcesPage(_, getters) {
      const resources = getters.resources;
      const current_page = resources.current_page;
      const last_page = resources.last_page;
      return last_page > current_page ? current_page + 1 : false;
    },
  },

  mutations: {
    resources(state, payLoad) {
      if (payLoad.refresh) {
        state.resources.data = payLoad.response.data;
      } else {
        state.resources.data.push(...payLoad.response.data);
      }
      state.resources.current_page = payLoad.response.current_page;
      state.resources.last_page = payLoad.response.last_page;
      state.resources.total = payLoad.response.total;
      localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    },

    allResources(state, payLoad) {
      state.allResources = payLoad;
    },

    active(state, payLoad) {
      state.active = payLoad;
    },

    filters(state, payLoad) {
      state.filters = payLoad;
    },

    segment(state, payLoad) {
      state.segment = payLoad;
    },

    specificResource(state, payLoad) {
      state.specificResource = payLoad;
    },
  },

  actions: {
    async resources(context, payLoad) {
      const refresh = payLoad.refresh ? true : false;
      const key = context.getters.resourcesKey;
      const filters = context.getters.filters;
      const segment = context.getters.segment;
      //   const filters = context.getters.filters;
      const resourceId = payLoad.resourceId;
      const inLocalStorage = refresh
        ? null
        : await JSON.parse(localStorage.getItem(key.localStorageName));

      if (inLocalStorage) {
        context.state.resources = inLocalStorage;
      }

      const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;

      if (nextResourcesPage) {
        //  const response = await ImportedApi.index(page)
        const response = await ExamSubject.index(
          nextResourcesPage,
          resourceId,
          filters,
          segment
        );

        const dispatch = {
          response: response.data,
          refresh: refresh,
          key: key.localStorageName,
        };
        context.commit("resources", dispatch);
      }
    },

    async createResource(context, payLoad) {
      // const response = await ImportedApi.create(resource_id)
      await ExamSubject.create(payLoad);
      context.dispatch("resources", {
        refresh: true,
        resourceId: payLoad.exam_id,
      });
    },

    async updateResource(context, payLoad) {
      // const response = await ImportedApi.update(resource_id, data)
      const data = payLoad;
      await ExamSubject.update(payLoad.id, data);
      context.dispatch("resources", {
        refresh: true,
        resourceId: payLoad.exam_id,
      });
    },

    async deleteResource(context, payLoad) {
      // const response = await ImportedApi.delete(resource_id)
      await ExamSubject.delete(payLoad.id);
      context.dispatch("resources", {
        refresh: true,
        resourceId: payLoad.exam_id,
      });
    },

    async allResources(context, payLoad) {
      // All resources without pagination
      const exam_id = payLoad;
      const allResources = await ExamSubject.all(exam_id);
      context.commit("allResources", allResources.data);
    },

    async active(context) {
      // All resources without pagination
      const active = await ExamSubject.active();
      context.commit("active", active.data);
    },

    async filters(context, payLoad) {
      await context.commit("filters", payLoad);
      if (!payLoad.filter_only) {
        context.dispatch("resources", {
          refresh: true,
          resourceId: payLoad.exam_id,
        });
      }
    },

    async segment(context, payLoad) {
      await context.commit("segment", payLoad.segment);
      context.dispatch("resources", {
        refresh: true,
        resourceId: payLoad.exam_id,
      });
    },

    async specificResource(context, payLoad) {
      // Specific resource
      const exam_subject_id = payLoad;
      const specificResource = await ExamSubject.show(exam_subject_id);
      context.commit("specificResource", specificResource.data);
    },
  },
};
