<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <Menu v-if="isAuthenticated"></Menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script>
import Menu from "./views/Components/Menu";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/vue";

export default {
  components: {
    Menu,
    IonApp,
    IonSplitPane,
    IonRouterOutlet,
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
};
</script>

<style>
.link {
  cursor: pointer;
}

.color-danger {
  color: #eb445a;
}

.disabledItem{
  text-decoration: line-through;
  color: grey;
}

.full-width {
  width: 100%;
}
</style>