import Api from '../base/index.js'

export default{
    // async index(page=1, id, segment){
    //     return Api.get(`exam-schedule/${id}`, {
    //         params : {
    //             page: page,
    //             segment: segment,
    //         }
    //     } )
    // },

    async all(id){
        return Api.get(`exam-answer-all/${id}`)
    },

    async create(data){
        return Api.post(`exam-answer`, data)
    },

    async update(id, data){
        return Api.put(`exam-answer/${id}`, data)
    },
    
    // async delete(id){
    //     return Api.delete(`exam-schedule/${id}`)
    // },

    // async control(id, data){
    //     return Api.post(`exam-schedule-control/${id}`, data)
    // }
}