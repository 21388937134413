// import Base from "./base.js";

// let token = localStorage.getItem("token");

// if (token) {
//   Base.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// }

// export default Base;


import axios from 'axios'
import base_url from '../base_url.js'

let Base = axios.create({
    baseURL: base_url+'api/v1/',
})

Base.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default Base