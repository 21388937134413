import axios from "axios";
import base_url from '../../base_url.js'

let Api = axios.create({
  baseURL: base_url+"api/v1/",
});

// let token = localStorage.getItem("token");

// if (token) {
//   Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// }

Api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);


export default Api;
